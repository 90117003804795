import { baseApi } from './base-service';

export interface RegisterRequest {
  email: string;
  phone: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  password: string;
  password2: string;
}
export interface UpdateRequest {
  userId: string;
  data: {
    firstName: string;
    surName: string;
    phoneNumber: string;
    email: string;
    dob: string;
    gender: string;
  };
  // phone: string;
  // password: string;
  // password2: string;
}
export interface LoginRequest {
  email: string;
  password: string;
}
export interface ForgotPasswordRequest {
  email: string;
}
export interface ForgotPinRequest {
  email: string;
  source: string;
}

export interface ResetPasswordRequest {
  token: string;
  email: string;
  password: string;
  // password2?: string;
}
export interface ResetPinRequest {
  token: string | null;
  pin: string;
  phoneNumber: string | null;
}
export interface ChangePasswordRequest {
  userId: string;
  oldPassword: string;
  password: string;
}
export interface CheckPinRequest {
  pin: string;
  phoneNumber: string;
}
export interface CreatePinRequest {
  pin: string;
  phoneNumber: string;
  // otp: string;
  source: string;
}

export const authServiceApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    registerUser: builder.mutation<any, any>({
      query: body => {
        return {
          url: `/web/user/register`,
          method: 'POST',
          body: body,
        };
      },
    }),
    updateUser: builder.mutation<any, UpdateRequest>({
      query: body => {
        return {
          url: `/web/user/update`,
          method: 'POST',
          body: body,
        };
      },
    }),
    loginUser: builder.mutation<any, LoginRequest>({
      query: body => {
        return {
          url: `/web/user/login`,
          method: 'POST',
          body: body,
        };
      },
    }),
    forgotPassword: builder.mutation<any, any>({
      query: body => {
        //TODO set domain from headers
        return {
          url: `/web/user/forgotpassword`,
          // url: `/web/user/password/forgot`,
          method: 'POST',
          body: body,
          //responseHandler: (response:ApiResponse<AuthResponse>) => response
        };
      },
    }),
    forgotPin: builder.mutation<any, ForgotPinRequest>({
      query: body => {
        return {
          url: `/users/pin/reset`,
          method: 'POST',
          body: body,
        };
      },
    }),
    resetPassword: builder.mutation<any, ResetPasswordRequest>({
      query: body => {
        //TODO set domain from headers
        return {
          // url: `/users/completereset`,
          url: `/web/user/completereset`,
          method: 'POST',
          body: body,
          //responseHandler: (response:ApiResponse<AuthResponse>) => response
        };
      },
    }),
    resetPin: builder.mutation<any, ResetPinRequest>({
      query: body => {
        return {
          url: `/users/pin/completeReset`,
          method: 'POST',
          body: body,
          //responseHandler: (response:ApiResponse<AuthResponse>) => response
        };
      },
    }),
    changePassword: builder.mutation<any, ChangePasswordRequest>({
      query: body => {
        return {
          url: `/web/user/changepassword`,
          method: 'POST',
          body: body,
          //responseHandler: (response:ApiResponse<AuthResponse>) => response
        };
      },
    }),
    getOTP: builder.mutation<any, any>({
      query: body => {
        return {
          url: `/web/otp/create`,
          method: 'POST',
          body,
        };
      },
    }),
    verifyAccount: builder.mutation<any, any>({
      query: body => {
        return {
          url: `/web/otp/verify`,
          method: 'POST',
          body,
        };
      },
    }),
    checkPin: builder.mutation<any, CheckPinRequest>({
      query: body => {
        return {
          url: `/users/check/pin`,
          method: 'POST',
          body,
        };
      },
    }),
    createPin: builder.mutation<any, CreatePinRequest>({
      query: body => {
        return {
          url: `/users/pin`,
          method: 'POST',
          body,
        };
      },
    }),
    createPhoneUser: builder.query<void, any>({
      query: data => ({
        url: `/users/profile?phoneNumber=${data.phone}&email=${data.email}`,
      }),
      transformResponse: (response: any) => response.data,
    }),
    getUserProfile: builder.query<void, string>({
      query: phone => ({
        url: `/users/profile?phoneNumber=${phone}`,
      }),
      transformResponse: (response: { data }) => response.data,
    }),
    deleteCard: builder.mutation<void, string>({
      query: cardId => {
        return {
          url: `/cards/${cardId}`,
          method: 'DELETE',
        };
      },
      transformResponse: (response: any) => response.data,
    }),
  }),
  overrideExisting: false,
});

export const {
  useRegisterUserMutation,
  useUpdateUserMutation,
  useLoginUserMutation,
  useForgotPasswordMutation,
  useForgotPinMutation,
  useResetPasswordMutation,
  useResetPinMutation,
  useChangePasswordMutation,
  useCheckPinMutation,
  useCreatePinMutation,
  useGetOTPMutation,
  useVerifyAccountMutation,
  useCreatePhoneUserQuery,
  useGetUserProfileQuery,
  useDeleteCardMutation,
} = authServiceApi;
