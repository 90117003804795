import styled from '@emotion/styled';
import Button from 'components/reusables/Button';
import Input from 'components/reusables/Input';
import amigo from '../../assets/images/amico.png';
import { useEffect, useRef } from 'react';
import {
  Logo,
  UnAuthContainer,
  UnAuthHeading,
  UnAuthSubHeading,
} from '../UnAuthPages/UnAuthReusables';
import { MAIN_GREEN } from 'styles/__color';
import {
  useGetOTPMutation,
  useVerifyAccountMutation,
} from 'redux/services/auth-services';
import { logoutUser, userData } from 'utility/helper';
import { message } from 'antd';
import { CREATEPIN, DASHBOARD } from 'pages/pagesPath';

const VerifyNumber = () => {
  const firstRef = useRef<any>();
  const secondRef = useRef<any>();
  const thirdRef = useRef<any>();
  const fourthRef = useRef<any>();

  const [getOTP] = useGetOTPMutation();
  const [verifyAccount] = useVerifyAccountMutation();

  const data = [
    {
      name: 'first',
      ref: firstRef,
      next: secondRef,
    },
    {
      name: 'second',
      ref: secondRef,
      next: thirdRef,
      prev: firstRef,
    },
    {
      name: 'third',
      ref: thirdRef,
      next: fourthRef,
      prev: secondRef,
    },
    {
      name: 'fourth',
      ref: fourthRef,
      prev: thirdRef,
    },
  ];

  // inputValues = [firstRef.current.value, secondRef.current.value, thirdRef.current.value, fourthRef.current.value]
  const handleRefInput = (e: any) => {
    const keyVal = e.which || e.keyCode;
    if (keyVal < 48 || keyVal > 57) e.preventDefault();
  };

  const handleEvent = (e, next, prev) => {
    const key = e.key;
    if (e.target.value.length && next) {
      next.current.focus();
    }
    if ((key === 'Backspace' || key === 'Delete') && prev) {
      prev.current.focus();
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();
    let otp = `${firstRef.current.value}${secondRef.current.value}${thirdRef.current.value}${fourthRef.current.value}`;
    let data = {
      otp,
      phone: userData().phoneNumber,
      userId: userData().userID ?? userData().userId,
    };
    // console.log(data);
    try {
      await verifyAccount(data).unwrap();
      let user = userData();
      localStorage.setItem('user', JSON.stringify({ ...user, verified: true }));
      if (!userData().hasPin) {
        window.location.pathname = CREATEPIN;
        console.log(userData().hasPin);
      } else {
        window.location.pathname = DASHBOARD;
      }
      // window.location.pathname = "/user/dashboard";
    } catch (error: any) {
      message.error(error?.data?.error);
    }
  }

  async function getOtpHandler() {
    let res: any = await getOTP({
      userId: userData().userID ?? userData().userId,
      phonenumber: userData().phoneNumber,
    });

    message.success(res?.data?.data);
  }

  useEffect(() => {
    firstRef.current.focus();
    // getOtpHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickHandler = () => {
    getOtpHandler();
  };

  return (
    <Wrapper>
      <div className='left-display'>
        {/* <li className="nav-item">
          <a href={voidLink} className="nav-link" onClick={() => logoutUser()}>
            <LogoutImg className="nav-svg" />
            <span className="ml-3">Log out</span>
          </a>
        </li> */}
        <h3>Track your spending on our services </h3>
        <p>
          With PayMyBills you can track all your spendings at the click of a
          button
        </p>
        <div className='center-image'>
          <img src={amigo} alt='paymybills' height='475px' width='490px' />
        </div>
      </div>
      <div className='right-display'>
        <Logo className='logo1' onClick={() => logoutUser()} />
        <UnAuthContainer>
          <UnAuthHeading>Verify Phone Number</UnAuthHeading>
          <UnAuthSubHeading>
            A 4-digit verification PIN has been sent to your phone, enter it
            below
          </UnAuthSubHeading>
          {/* <UnAuthSubHeading>
            A 4-digit verification PIN has been sent to <br />
            your phone, enter it below
          </UnAuthSubHeading> */}
          <form action='' onSubmit={e => handleSubmit(e)}>
            <div className='container'>
              {data.map((item, idx) => (
                <Input
                  type='number'
                  name={item.name}
                  key={idx}
                  maxLength={1}
                  refs={item.ref}
                  onKeyUp={e => handleEvent(e, item.next, item.prev)}
                  onKeyPress={handleRefInput}
                  pattern='[0-9]'
                />
              ))}
            </div>
            <Button styleClass='mr-4 mb-2' type='submit' label='Continue' />
          </form>

          <p className='account pointer'>
            Didn’t get the PIN? <b onClick={onClickHandler}>Resend</b> or{' '}
            <b onClick={() => logoutUser()}>Go Home</b>
          </p>
        </UnAuthContainer>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column nowrap;
  width: 100%;
  .left-display {
    background: ${MAIN_GREEN};
    color: white;
    width: 50%;
    height: 100vh;
    font-family: Mollen Personal Use;
    h3 {
      font-family: Mollen Personal Use;
      font-weight: bold;
      color: #fff;
      padding-top: 100px;
      padding-bottom: 20px;
      // font-size: 14px;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
    }
    p {
      width: 70%;
      margin: 0 auto;
      font-size: 14px;
      // line-height: 24px;
      line-height: 16.8px;
      text-align: center;
      padding-bottom: 40px;
    }
    // img {
    //   position: absolute;
    //   left: 120px;
    //   //   left: 50px;
    // }
    .center-image {
      display: flex;
      justify-content: center; /* horizontally center */
      align-items: center; /* vertically center */
    }
    .center-image img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .pointer {
    cursor: pointer;
  }

  .right-display {
    display: flex;
    flex-direction: column;
    width: 40%;
    margin: 0 auto;
    height: 80vh;
    text-align: center;
    justify-content: center;
  }
  .container {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    width: 60%;
    margin: 4rem auto;
    gap: 1rem;
    // margin: 0 auto;
    & > div {
      width: 25%;
      .form__input {
        height: 3rem !important;
        width: 3rem !important;
        font-size: 18px;
        font-weight: bold;
        background: #f9fffb;
      }
    }
  }
  //   .w {
  //     width: 80%;
  //     justify-items: center;
  //     text-align: center;
  //   }
  //   .account {
  //     text-align: center;
  //     color: #7b7b7b;
  //     font-size: 1.4rem;
  //     margin-top: 2rem;
  //     span {
  //       color: #03b66b;
  //       cursor: pointer;
  //     }
  //   }
  .account {
    color: #7b7b7b;
    font-size: 14px;
    margin-top: 2rem;
    line-height: 17px;
    justify-items: center;
    b {
      color: #03b66b;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
    }
  }

  @media screen and (max-width: 1200px) {
    .center-image {
      display: flex;
      justify-content: center; /* horizontally center */
      align-items: center; /* vertically center */
    }
    .center-image img {
      height: 300px;
      width: 300px;
    }
    .container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      //   width: 80%;
      width: 100%;
      margin-top: 4rem;
      margin-bottom: 4rem;
      padding-left: 0px;
      // margin: 0 auto;
      & > div {
        margin: 0;
        width: 10%;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .center-image {
      display: flex;
      justify-content: center; /* horizontally center */
      align-items: center; /* vertically center */
    }
    .center-image img {
      height: 300px;
      width: 300px;
    }
  }

  @media screen and (max-width: 500px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    // padding: 0px;
    .right-display {
      width: 100%;
    }
    .left-display {
      display: none;
    }
    .container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      //   width: 80%;
      width: 100%;
      margin-top: 4rem;
      margin-bottom: 4rem;
      padding-left: 0px;
      // margin: 0 auto;
      & > div {
        margin: 0;
        width: 10%;
      }
    }
  }
`;

export default VerifyNumber;
