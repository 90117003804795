import React from "react";
import loaderImg from "../../assets/images/Walk.gif";
interface WithSuspenseProps {
  page: string;
  data?: Record<string, any>;
}

function withSuspense(args: WithSuspenseProps): React.FC<any> {
  const { page, data } = args;

  return function InnerComponent(
    props: Record<string, any>
  ): React.ReactElement<Record<string, any>> {
    const LazyComponent = React.lazy(() => import(`pages/${page}`));

    return (
      <React.Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <img src={loaderImg} alt="loader" className="spin-loader" />
            </div>
          </div>
        }
      >
        <LazyComponent {...props} {...data} />
      </React.Suspense>
    );
  };
}

export default withSuspense;
