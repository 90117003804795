import { css } from '@emotion/react';
import OrkneyRegular from '../assets/fonts/orkney-regular.otf';
import OrkneyBold from '../assets/fonts/orkney-bold.otf';
import MollenRegular from 'assets/fonts/mollen-personal-use.regular.otf';
import MollenBold from 'assets/fonts/mollen-personal-use.bold.otf';
import PoppinsSemiBold from 'assets/fonts/poppins-semibold.otf';

export default css`
  @font-face {
    font-family: Orkney;
    src: url(${OrkneyRegular}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: Orkney-Bold;
    src: url(${OrkneyBold}) format('truetype');
    font-weight: bold;
    font-style: bold;
  }
  @font-face {
    font-family: Mollen Personal Use;
    src: url(${MollenRegular}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: Mollen Personal Use-Bold;
    src: url(${MollenBold}) format('truetype');
    font-weight: bold;
    font-style: bold;
  }
  @font-face {
    font-family: Poppins Semi-Bold;
    src: url(${PoppinsSemiBold}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }
  html {
    // font-size: 62.5%;
  }
  body {
    font-family: Orkney, 'Fira Sans', sans-serif;
    background: #fdfffe;
    box-sizing: border-box;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
  div::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
    height: 5px;
  }

  /* Handle */
  div::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
  }
  /* 
  .text-dec-none{
    text-decoration: none;
  }
  .panelhead {
  font-weight: bolder;
  font-size: 5vw;
  }

  .pmb-blue,
  span {
    color: #3e3d75;
  }

  .pmb-blue-bg {
    background-color: #3e3d75;
  }
  .PhoneInput{
    position: relative;
  } 

  //   /* .PhoneInput .PhoneInputInput{
  //   padding: 1.5rem 1.5rem 1.5rem 6rem !important;
  // }
  // .PhoneInput .PhoneInputCountry{
  //   position : absolute;
  //   top: 32%;
  //   left: 5%;
  // } */
`;
