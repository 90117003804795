import withSuspense from "components/HOC/WithSuspens";
import {
  TRANSACTIONS,
  CARDS,
  DASHBOARD,
  SERVICES,
  // PROFILE,
  SETTINGS,
  ACCOUNT,
  EVENTS,
  NOTIFICATIONS,
} from "../pagesPath";

export const protectedRoutes = [
  {
    path: DASHBOARD,
    component: withSuspense({ page: "AuthPages/Dashboard" }),
    exact: true,
  },
  {
    path: SERVICES,
    component: withSuspense({ page: "AuthPages/Services" }),
    exact: true,
  },
  {
    path: `${SERVICES}/:event`,
    component: withSuspense({ page: "AuthPages/IndividualService" }),
    exact: true,
  },
  {
    path: TRANSACTIONS,
    component: withSuspense({ page: "AuthPages/Transactions" }),
    exact: false,
  },
  {
    path: CARDS,
    component: withSuspense({ page: "AuthPages/Cards" }),
    exact: false,
  },
  // {
  //   path: PROFILE,
  //   component: withSuspense({ page: "AuthPages/Profile" }),
  //   exact: false,
  // },
  {
    path: ACCOUNT,
    component: withSuspense({ page: "AuthPages/Account" }),
    exact: false,
  },
  {
    path: SETTINGS,
    component: withSuspense({ page: "AuthPages/Settings" }),
    exact: false,
  },
  {
    path: EVENTS,
    component: withSuspense({ page: "AuthPages/Events" }),
    exact: false,
  },
  {
    path: NOTIFICATIONS,
    component: withSuspense({ page: "AuthPages/Notifications" }),
    exact: false,
  },
];
