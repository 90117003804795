import withSuspense from "components/HOC/WithSuspens";

export const publicRoutes = [
  {
    path: "/",
    component: withSuspense({ page: "UnAuthPages/LandingPage" }),
    exact: true,
  },
  {
    path: "/faqs",
    component: withSuspense({ page: "UnAuthPages/Faqs" }),
    exact: true,
  },
  {
    path: "/terms",
    component: withSuspense({ page: "UnAuthPages/Terms" }),
    exact: true,
  },
  {
    path: "/privacy-policy",
    component: withSuspense({ page: "UnAuthPages/PrivacyPolicy" }),
    exact: true,
  },
  {
    path: "/signup",
    component: withSuspense({ page: "UnAuthPages/SignUp" }),
    exact: true,
  },
  {
    path: "/login",
    component: withSuspense({ page: "UnAuthPages/Login" }),
    exact: true,
  },
  // {
  //   path: "/verify-number",
  //   component: withSuspense({ page: "UnAuthPages/VerifyNumber" }),
  //   exact: true,
  // },
  {
    path: "/verify-payment",
    component: withSuspense({ page: "UnAuthPages/VerifyPay" }),
    exact: true,
  },
  // {
  //   path: "/create-pin",
  //   component: withSuspense({ page: "UnAuthPages/CreatePinPage" }),
  //   exact: true,
  // },
  {
    path: "/password/forgot",
    component: withSuspense({ page: "UnAuthPages/ForgotPassword" }),
    exact: true,
  },
  {
    path: "/password/reset",
    component: withSuspense({ page: "UnAuthPages/ResetPassword" }),
    exact: true,
  },
  {
    path: "/pin/forgot",
    component: withSuspense({ page: "UnAuthPages/ForgotPin" }),
    exact: true,
  },
  {
    path: "/pin/reset",
    component: withSuspense({ page: "UnAuthPages/ResetPin" }),
    exact: true,
  },
  {
    path: "/mycover/form/:type/:phone/",
    component: withSuspense({page: "UnAuthPages/InsurancePage"}),
    // exact: true
  },
  {
    path: "/mycover/summary/:type/:phone",
    component: withSuspense({page: "UnAuthPages/InsuranceSummary"}),
    exact: true,
  },
  {
    path: "/uridium/form/:type/:phone",
    component: withSuspense({page: "UnAuthPages/InsurancePage"}),
    exact: false
  },
  {
    path: "/uridium/summary/:type/:phone",
    component: withSuspense({page: "UnAuthPages/InsuranceSummary"}),
    exact: true,
  }
];
