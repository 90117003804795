import React from 'react';
import styled from 'styled-components';

interface ButtonTypes {
  label?: string;
  func?: any;
  fullwidth?: boolean;
  styleClass?: string;
  bg?: string;
  border?: string;
  disabled?: boolean;
  loading?: boolean;
  text?: string;
  type?: string;
  rounded?: boolean;
}
const Button = (props: ButtonTypes) => {
  const {
    label,
    func,
    fullwidth,
    styleClass,
    bg,
    border,
    disabled,
    loading,
    text,
    type,
    rounded,
  } = props;
  return (
    <Button.Wrapper
      fullwidth={fullwidth}
      bg={bg}
      border={border}
      text={text}
      rounded={rounded}
    >
      <input
        type={type ? type : 'button'}
        className={` ${styleClass}`}
        // className={`m-2 ${styleClass}`}
        value={loading ? 'Loading...' : label}
        disabled={disabled}
        onClick={e => func && func(e)}
      />
    </Button.Wrapper>
  );
};

Button.Wrapper = styled.div`
  input {
    padding: 12px 30.2px;
    ${props => props.fullwidth && 'width: -webkit-fill-available;'}
    border-radius: ${props => (props.rounded ? '30px' : '10px')};
    // border-radius: ${props => (props.rounded ? '30px' : '5px')};
    background-color: ${props => (props.bg ? props.bg : ' #03B66B;')};
    color: ${props => (props.text ? props.text : '#ffffff')};
    outline: none;
    font-weight: bold;
    width: 100%;
    // border: none;
    border: ${props => (props.border ? props.border : 'none')};
    :hover {
      opacity: 0.8;
    }
  }
  @media (max-width: 520px) {
    input {
      padding: 12px 30.2px;
      // border-radius: ${props => (props.rounded ? '30px' : '5px')};
      border-radius: ${props => (props.rounded ? '30px' : '10px')};
    }
  }
`;
export default Button;
