import styled from "styled-components";
import { ReactComponent as DashboardImg } from "assets/icons/dashboardImg.svg";
import { ReactComponent as ServicesImg } from "assets/icons/servicesImg.svg";
import { ReactComponent as TransactionsImg } from "assets/icons/transactionsImg.svg";
import { ReactComponent as AccountImg } from "assets/icons/accountImg.svg";
import { ReactComponent as SettingsImg } from "assets/icons/settingsImg.svg";
import { ReactComponent as LogoutImg } from "assets/icons/logoutImg.svg";
// import { ReactComponent as EventImg } from "assets/icons/pmb-events.svg";
import eventImg1 from "assets/images/pmb-events.png";
// import eventImg1 from "assets/images/eventImg1.png";
import homeLogo from "assets/images/blueLogo.64d3f262.png";

import { Link, NavLink } from "react-router-dom";
import {
  ACCOUNT,
  DASHBOARD,
  // EVENTS,
  SERVICES,
  SETTINGS,
  TRANSACTIONS,
} from "pages/pagesPath";
import { logoutUser } from "utility/helper";
const voidLink = "#";
const LeftNav = ({ setOpen }) => {
  return (
    <LeftNav.Wrapper onClick={() => setOpen(false)}>
      <Link to="/" className="mt-3 ml-3">
        <img src={homeLogo} width={100} alt="Pay My Bills logo" />
      </Link>
      <ul style={{ marginTop: "3rem" }}>
        <li className="nav-item">
          <NavLink
            to={DASHBOARD}
            className={
              window.location.pathname === "/user/dashboard"
                ? "nav-link activ"
                : "nav-link "
            }
          >
            <DashboardImg className="nav-svg" />
            <span className="ml-3">Dashboard</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to={SERVICES}
            className={
              window.location.pathname === "/user/services"
                ? "nav-link activ"
                : "nav-link "
            }
          >
            <ServicesImg className="nav-svg" />
            <span className=" ml-3">Services</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to={TRANSACTIONS}
            className={
              window.location.pathname === "/user/transactions"
                ? "nav-link activ"
                : "nav-link "
            }
          >
            <TransactionsImg className="nav-svg" />
            <span className="ml-3">Transactions</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to={ACCOUNT}
            className={
              window.location.pathname === "/user/account"
                ? "nav-link activ"
                : "nav-link "
            }
          >
            <AccountImg className="nav-svg" />
            <span className="ml-3">Account</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to={SETTINGS}
            className={
              window.location.pathname === "/user/settings"
                ? "nav-link activ"
                : "nav-link "
            }
          >
            <SettingsImg className="nav-svg" />
            <span className="ml-3">Settings</span>
          </NavLink>
        </li>
        <li className="nav-item  mt-5">
          <a
            href="https://events.paymybills.ng/"
            style={{ textDecoration: "none" }}
            className="nav-link"
            // className="b-ticket"
            target="_blank"
            rel="noreferrer"
          >
            <p className="mb-3">
              <b>PayMyBills</b> Events
            </p>
            {/* <EventImg /> */}
            <img src={eventImg1} alt="Pay My Bills events link" />
          </a>
        </li>
        <li className="nav-item">
          <a href={voidLink} className="nav-link" onClick={() => logoutUser()}>
            <LogoutImg className="nav-svg" />
            <span className="ml-3">Log out</span>
          </a>
        </li>
      </ul>
    </LeftNav.Wrapper>
  );
};

LeftNav.Wrapper = styled.div`
  // background: white;
  font-family: Mollen Personal Use;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 12px;
  .container {
  }
  ul {
    list-style-type: none;
  }
  // .nav-item a.nav-link.active {
  //   color: #666ee8;
  //   border: 1px solid #666ee8;
  //   border-radius: 0.25rem;
  //   background-color: #fff;
  // }

  .nav-item {
    margin-bottom: 1rem;
    .nav-link {
      color: #000;
      font-size: 14px;
      line-height: 14px;
      justify-items: center;
      fill: #000;
    }
    .activ {
      color: #03b66b;
      .nav-svg {
        fill: #03b66b;
      }
      .mb-3 {
        color: #03b66b;
      }
    }

    .nav-svg {
      fill: #000;
    }
    .mb-3 {
      color: #0f227a;
    }
  }
  .nav-item:hover,
  .nav-item:focus {
    .nav-link {
      color: #03b66b;
    }
    .nav-svg {
      fill: #03b66b;
    }
  }
  .nav-item nav-link.active {
    color: blue;
    background-color: yellow;
    // .nav-link {
    //   color: #03b66b;
    // }
    // .nav-svg {
    //   fill: #03b66b;
    // }
  }

  @media (max-width: 920px) {
    width: 250px;
    background: #ffffff;
    // height: -webkit-fill-available;
    min-height: 100vh;
  }
`;
export default LeftNav;
