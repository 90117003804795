export const NOT_FOUND = "/404";
export const SERVER_ERROR = "/500";
export const UNAUTHORIZED = "/401";

export const USER = "/user";
export const LOGIN = "/login";
export const SIGNUP = "/signup";
export const FAQS = "/faqs";
export const TERMS = "/terms";
export const PRIVACY = "/privacy-policy";
export const PFORGOT = "/password/forgot";
export const PRESET = "/password/reset";
export const PIRESET = "/pin/reset";
export const PIFORGOT = "/pin/forgot";
export const INSURANCEPAGE = "/insurance/bot/form";
// auth paths
export const VERIFY = "/user/verify";
export const CREATEPIN = "/user/create-pin";
export const DASHBOARD = "/user/dashboard";
export const SERVICES = "/user/services";
export const TRANSACTIONS = "/user/transactions";
export const CARDS = "/user/cards";
// export const PROFILE = "/user/profile";
export const ACCOUNT = "/user/account";
export const SETTINGS = "/user/settings";
export const EVENTS = "/user/events";

export const NOTIFICATIONS = "/user/notifications";

