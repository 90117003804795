import AuthPages from "./AuthPages";
import UnAuthPages from "./UnAuthPages";
import { NOT_FOUND, UNAUTHORIZED, USER } from "./pagesPath";
import withSuspense from "components/HOC/WithSuspens";

const routes = [
  {
    path: NOT_FOUND,
    component: withSuspense({ page: "NotFound" }),
    exact: true,
  },
  {
    path: UNAUTHORIZED,
    component: withSuspense({ page: "Unauthorized" }),
    exact: true,
  },
  {
    path: USER,
    component: AuthPages,
    exact: false,
  },
  {
    path: "/",
    component: UnAuthPages,
    exact: false,
  },
];

export default routes;
