import { createSlice } from "@reduxjs/toolkit";

export const departmentSlice = createSlice({
  name: "department",
  initialState: { tempData: null, newEmployeeIds: [], employees: [] },

  reducers: {
    clearTempData: (state) => {
      return {
        ...state,
        tempData: null,
      };
    },
    clearEmployeeId: (state) => {
      return {
        ...state,
        newEmployeeIds: [],
      };
    },
    clearEmployees: (state) => {
      return {
        ...state,
        employees: [],
      };
    },
    clearAllDepartment: (state) => {
      return {
        ...state,
        tempData: null,
        newEmployeeIds: [],
        employees: [],
      };
    },
    setTempData: (state, action) => {
      return {
        ...state,
        tempData: action.payload,
      };
    },
    setNewEmployeeIds: (state, action) => {
      return {
        ...state,
        newEmployeeIds: action.payload,
      };
    },
    setEmployees: (state, action) => {
      return {
        ...state,
        employees: action.payload,
      };
    },
  },
});

export default departmentSlice.reducer;

export const {
  clearTempData,
  clearEmployeeId,
  clearEmployees,
  setNewEmployeeIds,
  clearAllDepartment,
  setEmployees,
  setTempData,
} = departmentSlice.actions;
