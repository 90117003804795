import "chart.js/auto";
import { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
// import { Doughnut } from "react-chartjs-2";
import moment from "moment";
import { Chart, getElementAtEvent } from "react-chartjs-2";
import { useChartDataQuery } from "redux/services/transaction-service";
import { userData } from "utility/helper";
import { chartData } from "helpers/chartData";
import type { InteractionItem } from "chart.js";

import { Chart as ChartJS } from "chart.js";

const DoughnutChart = () => {
  // const [months, setMonths] = useState(12);
  // const [updated, setUpdated] = useState<any>();
  const [totalDate, setTotalDate] = useState(
    moment().subtract(3, "months").format("MM-DD-YYYY")
  );
  const { data, refetch, isLoading }: any = useChartDataQuery({
    userId: userData().userID,
    date: totalDate,
  });
  const chartRef = useRef<ChartJS>(null);
  if (data !== undefined) {
    var singleAmt = chartData(data);
  }
  const data1 = {
    labels: [
      "Airtime",
      "Data",
      "Cable TV",
      "Electricity",
      "Internet",
      "WAEC Pin",
      "Insurance",
    ],
    datasets: [
      {
        label: "Amount Spent",
        data: [
          singleAmt?.airtimeAmt || 0,
          singleAmt?.dataAmt || 0,
          singleAmt?.cabletvAmt || 0,
          singleAmt?.electricityAmt || 0,
          singleAmt?.internetAmt || 0,
          singleAmt?.waecAmt || 0,
          singleAmt?.insuranceAmt || 0,
        ],
        backgroundColor: [
          "#20C979",
          "#0D58C7",
          "#138C6C",
          "#70DA6D",
          "#78f9f1",
          "#0F227A",
          "#07422A",
          // "#7FFFD4",
        ],
        //   borderColor: [
        //     "#20C979",
        //     "#0D58C7",
        //     "#138C6C",
        //     "#70DA6D",
        //     "#0F227A",
        //     "#07422A",
        //     "#7FFFD4",
        //   ],
        //   borderWidth: 1,
        hoverOffset: 4,
      },
    ],
  };

  // const options = {
  //   scales: {
  //     yAxes: [
  //       {
  //         ticks: {
  //           beginAtZero: true,
  //         },
  //       },
  //     ],
  //   },
  // };

  // const options = {
  //   scales: {
  //     y: {
  //       beginAtZero: true,
  //     },
  //   },
  // };

  const printElementAtEvent = (element: InteractionItem[]) => {
    if (!element.length) return;

    // const { datasetIndex, index } = element[0];

    // console.log(data1.labels[index], data1.datasets[datasetIndex].data[index]);
  };

  const onClickHandler = (event) => {
    const { current: chart } = chartRef;

    if (!chart) {
      return;
    }

    printElementAtEvent(getElementAtEvent(chart, event));
  };

  const handleDurationChange = (e) => {
    const val = e.target.value;
    if (val === "all") {
      setTotalDate("");
    } else {
      setTotalDate(
        moment().subtract(parseInt(val), "months").format("MM-DD-YYYY")
      );
    }
  };

  // to get the months passed
  // const handleDurationChange = (e) => {
  //   const val = e.target.value;
  //   if (val === "all") {
  //     setUpdated(null);
  //   } else {
  //     setMonths(parseInt(val));
  //   }
  // };

  // useEffect(() => {
  //   getDate();
  //   // eslint-disable-next-line
  // }, [months]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [totalDate]);

  // To get the date
  // const getDate = () => {
  //   let myDate = new Date(); //initializes the present date
  //   myDate.setMonth(myDate.getMonth() - months); //sets the date based on the new month
  //   let options: Intl.DateTimeFormatOptions = {
  //     // weekday: "long",
  //     year: "numeric",
  //     month: "2-digit",
  //     day: "2-digit",
  //   };
  //   setUpdated(
  //     myDate
  //       .toLocaleDateString("en-US", options) // using this format because its specified from the backend
  //       .replaceAll("/", "-")
  //   );
  //   // return updated;
  // };

  return (
    <>
      <Wrapper>
        <p className="sText">Most Used Services</p>
        <select
          name="duration"
          id="duration"
          className="sDuration"
          // value={tab}
          defaultValue={"3"}
          onChange={handleDurationChange}
        >
          <option value="3">3 months</option>
          <option value="6">6 months</option>
          <option value="9">9 months</option>
          <option value="12">12 months</option>
          <option value="all">all</option>
        </select>
      </Wrapper>
      {!isLoading ? (
        <>
          {data?.length > 0 ? (
            // <Doughnut data={data1} />
            <Chart
              ref={chartRef}
              type="doughnut"
              data={data1}
              // options={options}
              onClick={onClickHandler}
            />
          ) : (
            <div className="mt-4 pt-4">
              <h4 className="my-4 text-center text-pry">No chart available</h4>
              <h6 className="my-4 text-center">Please perform a transaction</h6>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  .sText {
    font-size: 12px;
    color: #0f227a;
    margin-inline-end: auto;
    margin-bottom: 0rem;
    padding: 0.5rem;
  }
  .sDuration {
    // display: inline;
    border: none;
    font-size: 12px;
    color: #0f227a;
    margin-inline-start: auto;
    // margin-bottom: 1rem;
    padding: 0.5rem;
  }
`;

export default DoughnutChart;
