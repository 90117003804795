import jwtDecode from "jwt-decode";
import moment from "moment";

export const userData = () => {
  let token: any = localStorage.getItem("token");
  try {
    jwtDecode(token);
    const { exp }: any = jwtDecode(token);
    if (Date.now() >= exp * 1000) {
      logoutUser();
    }
  } catch (err) {
    // return false;
  }
  let rawUserData: any = localStorage.getItem("user");

  let user = JSON.parse(rawUserData);
  return user;
};
export const decodeToken = (data: any) => {
  // const user = jwt(data.data.token); // decode your token here
  localStorage.setItem("token", data.data.token);
  localStorage.setItem("user", JSON.stringify(data.data));
};

export function logoutUser() {
  // localStorage.removeItem("token");
  // localStorage.removeItem("user");
  localStorage.clear();
  window.location.pathname = "/login";
}

export function isAuth() {
  let isLoggedIn = localStorage.getItem("token");
  if (isLoggedIn) {
    return true;
  } else {
    return false;
  }
}

export const frontend_url = process.env.REACT_APP_FRONTEND_URL;

export function greetings() {
  var today = new Date();
  var curHr = today.getHours();

  if (curHr < 12) {
    return "Good morning";
  } else if (curHr < 18) {
    return "Good afternoon";
  } else {
    return "Good evening";
  }
}

export function clearLocalStorage() {
  localStorage.removeItem("amount");
  localStorage.removeItem("phoneNumber");
  localStorage.removeItem("network");
  localStorage.removeItem("cardAuth");
  localStorage.removeItem("userPhone");
  localStorage.removeItem("userEmail");
  localStorage.removeItem("cableTvType");
  localStorage.removeItem("smartCardNumber");
  localStorage.removeItem("customerName");
  localStorage.removeItem("customerNumber");
  localStorage.removeItem("cableName");
  localStorage.removeItem("cablePrice");
  localStorage.removeItem("cableId");
  localStorage.removeItem("startimesAmount");
  localStorage.removeItem("name");
  localStorage.removeItem("price");
  localStorage.removeItem("planId");
  localStorage.removeItem("address");
  localStorage.removeItem("meterNo");
  localStorage.removeItem("acctNo");
  localStorage.removeItem("custAcctType");
  localStorage.removeItem("dtNo");
  localStorage.removeItem("discoName");
  localStorage.removeItem("code");
  localStorage.removeItem("params");
  localStorage.removeItem("referenceNumber");
  localStorage.removeItem("count");
  localStorage.removeItem("type");
  localStorage.removeItem("customerAccountId");
  localStorage.removeItem("typeCode");
  localStorage.removeItem("planName");
  localStorage.removeItem("payload");
  // localStorage.removeItem("businessUnit");
}

export function storeToLocalStorage({ data, type }) {
  console.log({ data });
  try {
    switch (type) {
      case "vehicle":
        localStorage.setItem("insuranceType", `${data.insuranceType}`);
        localStorage.setItem("type", `${data.type}`);
        localStorage.setItem("insuredName", `${data.insuredName}`);
        localStorage.setItem("provider", `${data.provider || "uridium"}`);
        localStorage.setItem("serviceProvider", `${data.serviceProvider}`);
        localStorage.setItem("numberPlate", `${data.numberPlate}`);
        localStorage.setItem("vehicleMake", `${data.vehicleMake}`);
        localStorage.setItem("vehicleModel", `${data.vehicleModel}`);
        localStorage.setItem("vehicleColor", `${data.vehicleColor}`);
        localStorage.setItem("vehicleCategory", `${data.vehicleCategory}`);
        localStorage.setItem("vehicleCapacity", `${data.vehicleCapacity}`);
        localStorage.setItem("vehiclePurpose", `${data.vehiclePurpose}`);
        localStorage.setItem("yearOfMake", `${data.yearOfMake}`);
        localStorage.setItem("dateOfBirth", `${data.dateOfBirth}`);
        localStorage.setItem("policyType", `${data.policyType}`);
        localStorage.setItem("phoneNo", `${data.phoneNo}`);
        localStorage.setItem("phoneNumber", `${data.phoneNo}`);
        localStorage.setItem("email", `${data.email}`);
        localStorage.setItem("address", `${data.address}`);
        localStorage.setItem("state", `${data.state}`);
        localStorage.setItem("sumCover", `${data.sumCover}`);
        localStorage.setItem("chassisNo", `${data.chasisNo}`);
        localStorage.setItem("engineNo", `${data.engineNo}`);
        console.log("setting data in localstore", data.insuranceType);
        return data
      case "health":
        localStorage.setItem("provider", data.provider || "mycover");
        localStorage.setItem("type", data.type);
        localStorage.setItem("firstName", data.firstName);
        localStorage.setItem("lastName", data.lastName);
        localStorage.setItem("address", data.address);
        localStorage.setItem("dob", data.dob);
        localStorage.setItem("gender", data.gender);
        localStorage.setItem("email", data.email);
        localStorage.setItem("paymentPlan", data.paymentPlan);
        localStorage.setItem("insurancePlan", data.insurancePlan);
        let plan = JSON.parse(data.insurancePlan);
        localStorage.setItem("phoneNumber", data.phoneNumber || data.phoneNo);
        localStorage.setItem("productId", plan.id);
        localStorage.setItem("productName", plan.name);
        // localStorage.setItem("image", data.image);
        localStorage.setItem("amount", `${plan.price}`);
        localStorage.setItem("serviceProvider", plan.provider.company_name);
        console.log("Setting for=> ", type, { data });
        return data
      case "gadget":
        try {
          localStorage.setItem("provider", data.provider || "mycover");
          localStorage.setItem("type", data.type);
          localStorage.setItem("firstName", data.firstName);
          localStorage.setItem("lastName", data.lastName);
          localStorage.setItem("address", data.address);
          localStorage.setItem("title", data.title);
          localStorage.setItem("dob", data.dob);
          localStorage.setItem("gender", data.gender);
          localStorage.setItem("email", data.email);
          localStorage.setItem("paymentPlan", data.paymentPlan);
          localStorage.setItem("deviceModel", data.deviceModel);
          localStorage.setItem("deviceType", data.deviceType);
          localStorage.setItem("deviceMake", data.deviceMake);
          localStorage.setItem("deviceValue", data.deviceValue);
          localStorage.setItem("dateOfPurchase", data.dateOfPurchase);
          localStorage.setItem("deviceSerialNumber", data.deviceSerialNumber);
          localStorage.setItem("imeiOne", data.imeiOne);
          localStorage.setItem("imeiTwo", data.imeiTwo);
          localStorage.setItem("deviceColor", data.deviceColor);
          localStorage.setItem("insurancePlan", data.insurancePlan);
          let plan = JSON.parse(data.insurancePlan);
          localStorage.setItem("phoneNumber", data.phoneNumber || data.phoneNo);
          localStorage.setItem("productId", plan.id);
          localStorage.setItem("productName", plan.name);
          // localStorage.setItem("image", data.image);
          localStorage.setItem("modelNumber", data.modelNumber);
          // localStorage.setItem("deviceAboutImageUrl", data.deviceAboutImageUrl);
          let amount = parseInt(data.deviceValue) * 0.05;
          localStorage.setItem("amount", `${amount}`);
          localStorage.setItem("serviceProvider", plan.provider.company_name);
          console.log("Setting for=> ", type, { plan }, { data });
          return data
        } catch (e) {

          console.log("gadget error", e);
          return;
        }
      case "content":
        try {
          console.log("setting content", data.address);
          localStorage.setItem("provider", data.provider || "mycover");
          localStorage.setItem("type", data.type);
          localStorage.setItem("address", data.address);
          localStorage.setItem("title", data.title);
          localStorage.setItem("firstName", data.firstName);
          localStorage.setItem("lastName", data.lastName);
          localStorage.setItem("isFullYear", data.isFullYear);
          localStorage.setItem("identificationName", data.identificationName);
          // localStorage.setItem("identificationUrl", data.image);
          localStorage.setItem("lga", data.lga);
          localStorage.setItem("insuranceStartDate", moment().format('YYYY-MM-DD'));
          if (data.subType === "shop") {
            localStorage.setItem("shopType", data.shopType);
            localStorage.setItem("natureOfBusiness", data.natureOfBusiness);
            localStorage.setItem("natureOfStock", data.natureOfStock);
            localStorage.setItem("stockKeeping", data.stockKeeping);
            localStorage.setItem("stockTakingInterval", data.stockTakingInterval);
            localStorage.setItem("stockAmount", data.stockAmount);

          }
          if (data.subType === "office") {
            localStorage.setItem("tenancy", data.tenancy);
            localStorage.setItem("preOwnership", data.preOwnership);
            localStorage.setItem("officeItems", JSON.stringify(data.officeItems));
          }
          if (data.subType === "home") {
            localStorage.setItem("tenancy", data.tenancy);
            localStorage.setItem("preOwnership", data.preOwnership);
            localStorage.setItem("homeItems", JSON.stringify(data.homeItems));
            localStorage.setItem("description", data.description);
          }
          localStorage.setItem("dob", data.dob);
          localStorage.setItem("gender", data.gender);
          localStorage.setItem("email", data.email);
          localStorage.setItem("insurancePlan", data.insurancePlan);
          console.log({ insurancePlan: data.insurancePlan })
          let plan = JSON.parse(data.insurancePlan);
          localStorage.setItem("phoneNumber", data.phoneNumber || data.phoneNo);
          localStorage.setItem("productId", plan.id);
          localStorage.setItem("productName", plan.name);
          localStorage.setItem("image", data.image);
          localStorage.setItem("amount", plan.price);
          localStorage.setItem("subType", data.subType);
          localStorage.setItem("serviceProvider", plan.provider.company_name);
          console.log("Setting for=> ", type, { data });
          return data;
        } catch (e) {
          console.log(e)
        }
    }
  } catch (e: any) {
    return { error: e.message }
  }
}
export function getDataLocalStorage(type: string) {
  let data: any = {};
  try {
    data.provider = localStorage.getItem("provider");
    data.firstName = localStorage.getItem("firstName");
    data.lastName = localStorage.getItem("lastName");
    data.type = localStorage.getItem("type");
    data.address = localStorage.getItem("address");
    data.title = localStorage.getItem("title");
    data.dob = localStorage.getItem("dob");
    data.gender = localStorage.getItem("gender");
    data.email = localStorage.getItem("email");
    switch (type) {
      case "vehicle":
        //  data.type =   localStorage.getItem("insuranceType");
        //  data.insuranceType =   localStorage.getItem("insuranceType");
        data.insuredName = localStorage.getItem("insuredName");
        data.provider = localStorage.getItem("provider") || "uridium";
        data.serviceProvider = localStorage.getItem("serviceProvider");
        data.number_plate = localStorage.getItem("numberPlate");
        let make = localStorage.getItem("vehicleMake");

        data.vehicleMake = make ? JSON.parse(make).value : "";
        data.vehicle_make_id = make ? JSON.parse(make).key : "";
        let model = localStorage.getItem("vehicleModel");

        data.vehicleModel = model ? JSON.parse(model).value : "";
        data.vehicle_model_id = model ? JSON.parse(model).key : "";
        let color = localStorage.getItem("vehicleColor");
        data.vehicleColor = color ? JSON.parse(color).value : "";
        data.vehicle_color_id = color ? JSON.parse(color).key : "";
        data.vehicleCategory = localStorage.getItem("vehicleCategory");
        data.vehicle_category_id = data.vehicleCategory ? JSON.parse(data.vehicleCategory).key : "";
        data.vehicleCapacity = localStorage.getItem("vehicleCapacity");
        data.vehiclePurpose = localStorage.getItem("vehiclePurpose");
        data.vehicle_purpose_id = data.vehiclePurpose ? JSON.parse(data.vehiclePurpose).key : "";
        data.yearOfMake = localStorage.getItem("yearOfMake");
        data.dateOfBirth = localStorage.getItem("dateOfBirth");
        let poilcyType = localStorage.getItem("policyType") ||
          "{\"key\":\"\",\"value\":\"\"}";
        data.policyType = JSON.parse(poilcyType).value;
        data.insurance_policy_id = JSON.parse(poilcyType).key;
        data.insuranceType = localStorage.getItem("insuranceType");
        data.insurance_type_id = data.insuranceType;
        data.phoneNo = localStorage.getItem("phoneNo");
        data.phoneNumber =  localStorage.getItem("phoneNo");
        data.email = localStorage.getItem("email");
        data.state = localStorage.getItem("state");
        data.sumCover = localStorage.getItem("sumCover");
        data.chassisNo = localStorage.getItem("chassisNo");
        data.engineNo = localStorage.getItem("engineNo");
        // console.log("getting data for ", type, data)
        return data
      case "health":
        data.provider = localStorage.getItem("provider");
        data.type = localStorage.getItem("type");
        data.firstName = localStorage.getItem("firstName");
        data.lastName = localStorage.getItem("lastName");
        data.address = localStorage.getItem("address");
        data.phoneNumber = localStorage.getItem("phoneNumber");
        data.productId = localStorage.getItem("productId");
        data.productName = localStorage.getItem("productName");
        data.image = localStorage.getItem("image");
        data.amount = localStorage.getItem("amount");
        data.serviceProvider = localStorage.getItem("serviceProvider");
        data.paymentPlan = localStorage.getItem("paymentPlan");
        //  console.log("getting for ", type, data);
        return data
      case "gadget":
        data.modelNumber = localStorage.getItem("modelNumber");
        data.deviceAboutImageUrl = localStorage.getItem("deviceAboutImageUrl");
        data.paymentPlan = localStorage.getItem("paymentPlan");
        data.deviceModel = localStorage.getItem("deviceModel",);
        data.deviceType = localStorage.getItem("deviceType");
        data.deviceMake = localStorage.getItem("deviceMake");
        data.deviceValue = localStorage.getItem("deviceValue",);
        data.dateOfPurchase = localStorage.getItem("dateOfPurchase",);
        data.deviceSerialNumber = localStorage.getItem("deviceSerialNumber",);
        data.imeiOne = localStorage.getItem("imeiOne",);
        if (localStorage.getItem("imeiTwo")) { data.imeiTwo = localStorage.getItem("imeiTwo",) }
        data.deviceColor = localStorage.getItem("deviceColor",);
        data.insurancePlan = localStorage.getItem("insurancePlan",);
        data.phoneNumber = localStorage.getItem("phoneNumber");
        data.productId = localStorage.getItem("productId");
        data.productName = localStorage.getItem("productName");
        data.image = localStorage.getItem("image");
        data.amount = localStorage.getItem("amount");
        data.serviceProvider = localStorage.getItem("serviceProvider");
        console.log("getting for=> ", type, { data });
        return data
      case "content":
        let subType = localStorage.getItem('subType')
        console.log({ subType });
        data.isFullYear = localStorage.getItem("isFullYear");
        data.address = localStorage.getItem("address");
        data.title = localStorage.getItem("title");
        data.type = localStorage.getItem("type");
        data.identificationName = localStorage.getItem("identificationName");
        // data.identificationUrl = localStorage.getItem("identificationUrl");
        console.log("checking files", localStorage.getItem("image"), localStorage.getItem("identificationUrl"))
        data.insuranceStartDate = localStorage.getItem("insuranceStartDate");
        if (subType === "shop") {
          data.shopType = localStorage.getItem("shopType");
          data.natureOfBusiness = localStorage.getItem("natureOfBusiness");
          data.natureOfStock = localStorage.getItem("natureOfStock");
          data.stockKeeping = localStorage.getItem("stockKeeping");
          data.stockTakingInterval = localStorage.getItem("stockTakingInterval");
        data.identificationUrl = localStorage.getItem("image");

          data.description = localStorage.getItem("description");
          data.stockAmount = localStorage.getItem("stockAmount",);
        }
        if (subType === "office") {
          data.tenancy = localStorage.getItem("tenancy");
          data.preOwnership = localStorage.getItem("preOwnership");
          data.officeItems = JSON.parse(localStorage.getItem("officeItems") || "");
        }
        if (subType === "home") {
          data.tenancy = localStorage.getItem("tenancy");
          data.preOwnership = localStorage.getItem("preOwnership");
          data.homeItems = JSON.parse(localStorage.getItem("homeItems") || "");
          data.description = localStorage.getItem("description");
        }
        data.dob = localStorage.getItem("dob");
        data.lga = localStorage.getItem("lga");
        data.gender = localStorage.getItem("gender");
        data.email = localStorage.getItem("email");
        data.insurancePlan = localStorage.getItem("insurancePlan");
        data.phoneNumber = localStorage.getItem("phoneNumber");
        data.productId = localStorage.getItem("productId");
        data.productName = localStorage.getItem("productName");
        data.image = localStorage.getItem("image");
        data.amount = localStorage.getItem("amount");
        data.subType = localStorage.getItem("subType");
        data.serviceProvider = localStorage.getItem("serviceProvider");
        console.log("getting for=> ", type, { data });
        return data;

    }
  } catch (e: any) {
    return { error: e.message, e }
  }
}
