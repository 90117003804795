import { BrowserRouter as Router } from 'react-router-dom';
import { Global } from '@emotion/react';
import __globalCss from './styles/__global.css';
import { Provider } from 'react-redux';
import Pages from './pages';
import store from 'redux/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { message, notification } from 'antd';
import ReactGA from 'react-ga'
import { useEffect } from 'react';

ReactGA.initialize('G-9GV7M5M51K');



function App() {
  useEffect(() => {
    ReactGA.pageview('visited')
  }, [])
  AOS.init();
  message.config({
    top: 100,
    duration: 15,
    maxCount: 2,
  });
  notification.config({
    placement: 'bottomRight',
    bottom: 50,
    duration: 15,
    closeIcon: true ,
    rtl: true,
    maxCount:2,
  });
  return (
    <Provider store={store}>
      <Router>
        <Global styles={__globalCss} />
        <ToastContainer />
        <Pages />
      </Router>
    </Provider>
  );
}

export default App;
