// import { useState } from "react";

export const chartData = (bigData: any) => {
  if (bigData.length !== 0) {
    let airtime: any = [0];
    let data: any = [0];
    let internet: any = [0];
    let cabletv: any = [0];
    let waec: any = [0];
    let electricity: any = [0];
    // let misc: any = [0];
    let insurance:  any = [0]

    bigData.forEach((element) => {
      if (element?.type === "airtime") {
        airtime.push(element?.amount);
      } else if (element?.type === "data") {
        data.push(element?.amount);
      } else if (element?.type === "smile" || element?.type === "spectranet") {
        internet.push(element?.amount);
      } else if (
        element?.type === "dstv" ||
        element?.type === "gotv" ||
        element?.type === "showmax" ||
        element?.type === "startime"
      ) {
        cabletv.push(element?.amount);
      } else if (element?.type === "waec") {
        waec.push(element?.amount);
      } else if (element?.type === "electricity") {
        electricity.push(element?.amount);
      }else if(element?.type === "insurance" || element?.type === "vehicleinsurance" ||"vehicle" ) {
        insurance.push(element?.amount);
      }
      // else {
      //   misc.push(element.amount);
      // }
    });

    const reducer = (previousValue, currentValue) =>
      previousValue + currentValue;
    const airtimeAmt = airtime.reduce(reducer);
    const dataAmt = data.reduce(reducer);
    const internetAmt = internet.reduce(reducer);
    const cabletvAmt = cabletv.reduce(reducer);
    const waecAmt = waec.reduce(reducer);
    const electricityAmt = electricity.reduce(reducer);
    const insuranceAmt = insurance.reduce(reducer);
    // const miscAmt = misc.reduce(reducer);

    // console.log(
    //   airtimeAmt + dataAmt + internetAmt + cabletvAmt + waecAmt + electricityAmt
    // );

    return {
      airtimeAmt,
      dataAmt,
      internetAmt,
      cabletvAmt,
      waecAmt,
      electricityAmt,
      insuranceAmt
      // miscAmt,
    };
  }
};
