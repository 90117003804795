import { combineReducers } from "redux";
import departmentSliceReducer from "redux/slices/department-slice";

import { baseApi } from "./services/base-service";

const rootReducer = combineReducers({
  pmbWebApi: baseApi.reducer,
  department: departmentSliceReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
