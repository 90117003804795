import routes from "./routes";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { convertRoutesToComponents } from "helpers/pagesParser";

const Pages = (): any => {
  const dispatch = useDispatch();
  useEffect(() => {}, [dispatch]);
  return convertRoutesToComponents(routes);
};

export default Pages;
