/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from "@emotion/styled";
// import { useEffect } from "react";
// import { message } from "antd";
import "antd/dist/antd.min.css";
import { notification, Skeleton } from "antd";
import { useHistory } from "react-router-dom";
import { useVerifyTransactionQuery } from "redux/services/transaction-service";
import { useEffect, useState } from "react";

const VerifyPay = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const params = new URLSearchParams(window.location.search);
  let id: any = params.get("reference");
  const { data, error, isLoading } = useVerifyTransactionQuery(id);

  useEffect(() => {
    if (!isLoading && !error) {
      setLoading(false);
      notification.success({
        message: "Transaction is Processing",
        description: localStorage.getItem('insurance') === 'insure'?"KINDLY CHECK EMAIL FOR RECIEPT AND CERTIFICATE ": 'Your card has been charged',
      });
      setTimeout(() => {
        history.push("/user/dashboard");
        setTimeout(() => {
          localStorage.removeItem('insurance');
        }, 1000)
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);
  useEffect(() => {
    if (!isLoading && error) {
      setLoading(false);
      setTimeout(() => {
        history.push("/user/dashboard");
        setTimeout(() => {
          localStorage.removeItem('insurance');
        }, 1000)
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);
  return (
    <Wrapper>
      {data && !error && (
        <div className="">
          <h4>
            We've received your payment, your transaction will be completed in a
            bit.
          </h4>
        </div>
      )}
      {error && (
        <h4>Something went wrong, your U-Balance will be credited shortly</h4>
      )}
      {(loading || isLoading) && (
        <div className="col-sm-4">
          <Skeleton title paragraph active></Skeleton>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 500px) {
  }
`;

export default VerifyPay;
