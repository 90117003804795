import { RouterProps } from "react-router";
import { publicRoutes } from "./routes";
import { SERVICES } from "../pagesPath";
import { convertRoutesToComponents } from "helpers/pagesParser";

const UnAuthPages = (props: RouterProps) => {
  const { history } = props;

  if (localStorage.getItem("token")) {
    history.push(SERVICES);
  }

  return convertRoutesToComponents(publicRoutes);
};

export default UnAuthPages;
