import { TRANSACTIONS } from "pages/pagesPath";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import styled from "styled-components";
import success from "assets/icons/Rectangle 6.png";
import failure from "assets/icons/Rectangle 7.png";
import pending from "assets/icons/Group 1503.png";

type RNavItemProps = {
  name?: string;
  date?: string;
  status?: string;
  amount?: React.ReactNode;
};

const RNavItem = (props: RNavItemProps) => {
  const { name, date, status, amount } = props;
  return (
    <RNavItem.Wrapper
    // onClick={() => props.func()}
    >
      {/* <img src={props.img} alt={name} /> */}
      {status === "success" ? (
        <img src={success} alt="" />
      ) : status === "pending" ? (
        <img src={pending} alt="" />
      ) : (
        <img src={failure} alt="" />
      )}
      <div className="coll">
        <p className="title mb-0">{name}</p>
        {/* <span className="subtitle">{props.no} transaction</span> */}
        <span className="subtitle">
          <Moment format="YYYY-MM-DD hh:mm:ss">{date}</Moment>
        </span>
      </div>
      <b>
        {amount}
        <Link to={TRANSACTIONS}>
          <i className="fas fa-angle-right ml-3 mb-0 p-0"></i>
        </Link>
      </b>
    </RNavItem.Wrapper>
  );
};
RNavItem.Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  b:nth-of-type(1) {
    font-family: Mollen Personal Use-Bold;
  }
  p {
    color: #3e3d75;
    font-size: 13px;
  }
  span {
    color: #3e3d75cc;
    font-size: 11px;
  }
  b {
    color: #2c2c4b;
    font-size: 12px;
  }
  i {
    color: #5e5475;
  }
  // .coll {
  //   flex-direction: column;
  //   justify-content: start;
  // }
  // flex-direction-column {
  //   justify-content: center;
  // }
`;
export default RNavItem;
