import { baseApi } from './base-service';

export interface TotalSpentRequest {
  userId: string;
  date: any;
}
export interface TransactionHistoryRequest {
  userId: string;
  page: number;
  limit: number;
  status?: null | string;
}

export const transactionServiceApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    verifyTransaction: builder.query<void, void>({
      query: id => ({
        url: `/payments/callback/${id}`,
      }),
      transformResponse: (response: any) => response.data,
    }),
    initializeTransaction: builder.mutation<any, any>({
      query: body => ({
        url: `/transactions/initialize`,
        method: 'POST',
        body: body,
      }),
      transformResponse: (response: any) => response.data,
    }),
    transactionHistory: builder.query<void, void>({
      query: phone => ({
        url: `/users/profile?phoneNumber=${phone}`,
      }),
      transformResponse: (response: any) => response.data,
    }),
    totalSpent: builder.query<void, TotalSpentRequest>({
      query: body => ({
        url: `/payments/totalspend?userId=${body.userId}&dateFrom=${body.date}&limit=1000000`,
      }),
      transformResponse: (response: any) => response.data,
    }),
    chartData: builder.query<void, TotalSpentRequest>({
      query: body => ({
        // url: `payments?userId=${body.userId}&status=success&dateFrom=${
        //   body.date ?? ""
        // }&limit=1000000`,
        url: `/web/transactions/chartData?limit=1000000&page=all&status=success&userId=${
          body.userId
        }&dateFrom=${body.date ?? ''}`,
        // default limit is 100, so best to set it to a high value here. What happens though, if a user exceeds this value?
      }),
      transformResponse: (response: any) => response.data,
    }),
    transactionListHistory: builder.query<void, TransactionHistoryRequest>({
      query: body => ({
        url: body.status
          ? `/web/transactions/chartData?limit=${body.limit}&page=${body.page}&userId=${body.userId}&status=${body.status}`
          : `/web/transactions/chartData?limit=${body.limit}&page=${body.page}&userId=${body.userId}`,
        // url: body.status
        //   ? `/payments?limit=${body.limit}&page=${body.page}&userId=${body.userId}&status=${body.status}`
        //   : `/payments?limit=${body.limit}&page=${body.page}&userId=${body.userId}`,
      }),
      transformResponse: (response: any) => response,
    }),
    navTransactionHistory: builder.query<void, void>({
      query: userId => ({
        url: `/web/transactions/chartData?userId=${userId}`,
        // url: `/payments?userId=${userId}`,
      }),
      transformResponse: (response: any) => response.data,
    }),
  }),
});

export const {
  useVerifyTransactionQuery,
  useInitializeTransactionMutation,
  useTransactionHistoryQuery,
  useTotalSpentQuery,
  useChartDataQuery,
  useTransactionListHistoryQuery,
  useNavTransactionHistoryQuery,
} = transactionServiceApi;
