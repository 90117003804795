import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import styled from '@emotion/styled';
// import CustomButton from "components/reusables/CustomButton";
import Input from 'components/reusables/Input';
import Button from 'components/reusables/Button';
import { MAIN_GREEN } from 'styles/__color';
import frontDesk from '../../assets/images/pana.png';
import {
  UnAuthHeading,
  UnAuthSubHeading,
  // UnAuthContainer,
  UnAuthLabel,
  Logo,
} from '../UnAuthPages/UnAuthReusables';
import {
  useCreatePinMutation,
  // useGetOTPMutation,
} from 'redux/services/auth-services';
import { isAuth, logoutUser, userData } from 'utility/helper';
import { message } from 'antd';

const CreatePinPage = () => {
  // const firstRef = useRef<any>();
  // const secondRef = useRef<any>();
  // const thirdRef = useRef<any>();
  // const fourthRef = useRef<any>();

  const fifthRef = useRef<any>();
  const sixthRef = useRef<any>();
  const seventhRef = useRef<any>();
  const eigthRef = useRef<any>();

  const ninthRef = useRef<any>();
  const tenthRef = useRef<any>();
  const eleventhRef = useRef<any>();
  const twelfthRef = useRef<any>();

  const [isLoading, setIsLoading] = useState(false);
  const userPhone = localStorage.getItem('userPhone');
  // const [getOTP] = useGetOTPMutation();
  const [createPin] = useCreatePinMutation();

  // const otpData = [
  //   {
  //     name: 'first',
  //     ref: firstRef,
  //     next: secondRef,
  //   },
  //   {
  //     name: 'second',
  //     ref: secondRef,
  //     next: thirdRef,
  //     prev: firstRef,
  //   },
  //   {
  //     name: 'third',
  //     ref: thirdRef,
  //     next: fourthRef,
  //     prev: secondRef,
  //   },
  //   {
  //     name: 'fourth',
  //     ref: fourthRef,
  //     prev: thirdRef,
  //   },
  // ];

  const pinData1 = [
    {
      name: 'fifth',
      ref: fifthRef,
      next: sixthRef,
    },
    {
      name: 'sixth',
      ref: sixthRef,
      next: seventhRef,
      prev: fifthRef,
    },
    {
      name: 'seventh',
      ref: seventhRef,
      next: eigthRef,
      prev: sixthRef,
    },
    {
      name: 'eigthRef',
      ref: eigthRef,
      prev: seventhRef,
    },
  ];

  const pinData2 = [
    {
      name: 'ninth',
      ref: ninthRef,
      next: tenthRef,
    },
    {
      name: 'tenth',
      ref: tenthRef,
      next: eleventhRef,
      prev: ninthRef,
    },
    {
      name: 'eleventh',
      ref: eleventhRef,
      next: twelfthRef,
      prev: tenthRef,
    },
    {
      name: 'twelfth',
      ref: twelfthRef,
      prev: eleventhRef,
    },
  ];

  // async function getOtpHandler() {
  //   let res: any = await getOTP({
  //     userId: userData().userID ?? userData().userId,
  //     phonenumber: userData().phoneNumber,
  //   });

  //   message.success(res?.data?.data);
  // }

  // useEffect(() => {
  //   firstRef.current.focus();
  //   getOtpHandler();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const onClickHandler = () => {
  //   getOtpHandler();
  // };

  const handleRefInput = (e: any) => {
    const keyVal = e.which || e.keyCode;
    if (keyVal < 48 || keyVal > 57) e.preventDefault();
  };

  const handleEvent = (e, next, prev) => {
    const key = e.key;
    if (e.target.value.length && next) {
      next.current.focus();
    }
    if ((key === 'Backspace' || key === 'Delete') && prev) {
      prev.current.focus();
    }
  };

  async function handleSubmit(e: any): Promise<void> {
    e.preventDefault();
    setIsLoading(true);
    // let otp = `${firstRef.current.value}${secondRef.current.value}${thirdRef.current.value}${fourthRef.current.value}`;
    let pin1 = `${fifthRef.current.value}${sixthRef.current.value}${seventhRef.current.value}${eigthRef.current.value}`;
    let pin2 = `${ninthRef.current.value}${tenthRef.current.value}${eleventhRef.current.value}${twelfthRef.current.value}`;
    if (pin1 !== pin2) {
      message.error('Pins do not match!');
      setIsLoading(false);
    } else {
      try {
        let data = {
          pin: pin2,
          phoneNumber: isAuth() ? userData().phoneNumber : userPhone,
          // otp,
          source: 'web',
        };
        const res: any = await createPin(data);
        if (res.data.status === 'success') {
          message.success(res.data.message);
          let user = userData();
          localStorage.setItem(
            'user',
            JSON.stringify({ ...user, hasPin: true })
          );
          window.location.pathname = '/user/dashboard';
        } else {
          setIsLoading(false);
          message.error(res.data.message);
        }
      } catch (error: any) {
        setIsLoading(false);
        message.error(error?.data?.error);
      }
    }
  }
  useEffect(() => {
    if(userData().hasPin){
      window.location.pathname = '/user/dashboard';
    }else{
      return
    }
  }, [])
  return (
    <Wrapper>
      <div className='left-display'>
        <h3>Track your spending on any of our services </h3>
        <p>
          With PayMyBills you can track all your spendings at the click of a
          button
        </p>
        <div className='center-image'>
          <img src={frontDesk} alt='paymybills' height='470px' width='500px' />
        </div>
      </div>
      <div className='right-display'>
        <Logo className='logo1' onClick={() => logoutUser()} />
        <div className='unauth-container'>
          <UnAuthHeading>Almost There</UnAuthHeading>
          <UnAuthSubHeading className='mb-4'>
            Let’s create your transactions PIN
          </UnAuthSubHeading>
          {/* <Input
            type="number"
            name="phoneNo"
            placeholder="Choose 4-digit PIN"
          />

          <Input type="number" name="password" placeholder="Confirm Pin" /> */}

          {/* <UnAuthButton>
            <CustomButton>Create PIN</CustomButton>
          </UnAuthButton> */}
          {/* <Link to="/verify-number"> */}
          {/* <Button styleClass="mr-4 mb-2" label="Create PIN" /> */}
          {/* </Link> */}
          <form onSubmit={e => handleSubmit(e)} className=''>
            {/* <UnAuthLabel>Enter OTP</UnAuthLabel>
            <div className='pin-div'>
              {otpData.map((item, idx) => (
                <Input
                  type='password'
                  name={item.name}
                  key={idx}
                  maxLength={1}
                  refs={item.ref}
                  onKeyUp={e => handleEvent(e, item.next, item.prev)}
                  onKeyPress={handleRefInput}
                  pattern='[0-9]'
                />
              ))}
            </div> */}

            <UnAuthLabel>Create 4-digits Pin</UnAuthLabel>
            <div className='pin-div'>
              {pinData1.map((item, idx) => (
                <Input
                  type='password'
                  name={item.name}
                  key={idx}
                  maxLength={1}
                  refs={item.ref}
                  onKeyUp={e => handleEvent(e, item.next, item.prev)}
                  onKeyPress={handleRefInput}
                  pattern='[0-9]'
                />
              ))}
            </div>

            <UnAuthLabel>Confirm Pin</UnAuthLabel>
            <div className='pin-div'>
              {pinData2.map((item, idx) => (
                <Input
                  type='password'
                  name={item.name}
                  key={idx}
                  maxLength={1}
                  refs={item.ref}
                  onKeyUp={e => handleEvent(e, item.next, item.prev)}
                  onKeyPress={handleRefInput}
                  pattern='[0-9]'
                />
              ))}
            </div>

            <Button
              styleClass='btn-submit'
              type='submit'
              loading={isLoading}
              disabled={isLoading}
              label='Continue'
            />
          </form>

          {/* 
          <p className='account pointer'>
            Didn’t get the PIN? <b onClick={onClickHandler}>Resend</b> or{' '} 
            <b onClick={() => logoutUser()}>Go Home</b>
          </p>
            */}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column nowrap;
  width: 100%;
  overflow: hidden;
  .left-display {
    background: ${MAIN_GREEN};
    color: white;
    width: 50%;
    height: 100vh;
    font-family: Mollen Personal Use;
    h3 {
      // font-family: Mollen Personal Use;
      font-weight: bold;
      color: #fff;
      padding-top: 100px;
      padding-bottom: 20px;
      // font-size: 14px;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
    }
    p {
      width: 70%;
      margin: 0 auto;
      font-size: 14px;
      // line-height: 24px;
      line-height: 16.8px;
      text-align: center;
      padding-bottom: 40px;
    }
    // img {
    //   position: absolute;
    //   left: 120px;
    //   //   left: 50px;
    // }
    .center-image {
      display: flex;
      justify-content: center; /* horizontally center */
      align-items: center; /* vertically center */
    }
    .center-image img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .pointer {
    cursor: pointer;
  }

  .right-display {
    display: flex;
    flex-direction: column;
    width: 25%;
    height: 85vh;
    text-align: center;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    /* overflow-y: scroll; */
    p {
      color: #7b7b7b;
      margin-bottom: -0.1rem;
    }

    .unauth-container {
      position: relative;
      /* padding: 1.5rem; */
      z-index: 10;
      // width: 45rem;
      /* width: 70%; */
      background-color: white;
      margin: 0 auto;
      top: 5rem;
      // top: 80px;
      // box-shadow: 0px 20px 70px rgba(0, 0, 0, 0.1);
      /* border-radius: 50px; */
      /* margin-bottom: 1rem; */
      // margin-bottom: 0rem;

      @media screen and (max-width: 960px) {
        width: 80%;
      }
    }
  }

  .pin-div {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    margin: 0 auto;
    /* margin-bottom: 1.5rem; */
    margin-bottom: 2rem;
    gap: 0.5rem;

    & > div {
      width: 25%;
      .form__input {
        height: 3rem !important;
        width: 3rem !important;
        font-size: 18px;
        font-weight: bold;
        background: #f9fffb;
        margin-bottom: 0;
      }
    }
  }

  /* .account {
    color: #7b7b7b;
    font-size: 14px;
    margin-top: 2rem;
    line-height: 17px;
    justify-items: center;
    b {
      color: #03b66b;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
    }
  } */

  @media screen and (max-width: 1200px) {
    .center-image {
      display: flex;
      justify-content: center; /* horizontally center */
      align-items: center; /* vertically center */
    }
    .center-image img {
      height: 300px;
      width: 300px;
    }

    .container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      //   width: 80%;
      width: 100%;
      /* margin-top: 4rem;
      margin-bottom: 4rem; */
      padding-left: 0px;
      // margin: 0 auto;
      & > div {
        margin: 0;
        width: 10%;
      }
    }

    .pin-div {
      & > div {
        .form__input {
          height: 3rem !important;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .center-image {
      display: flex;
      justify-content: center; /* horizontally center */
      align-items: center; /* vertically center */
    }
    .center-image img {
      height: 300px;
      width: 300px;
    }
  }

  @media screen and (max-width: 500px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    // padding: 0px;
    .right-display {
      width: 100%;
    }
    .left-display {
      display: none;
    }
    .container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      //   width: 80%;
      width: 100%;
      /* margin-top: 4rem; */
      /* margin-bottom: 4rem; */
      padding-left: 0px;
      // margin: 0 auto;
      & > div {
        margin: 0;
        width: 10%;
      }
    }

    .pin-div {
      & > div {
        .form__input {
          height: 3rem !important;
        }
      }
    }
  }

  @media screen and (min-width: 1400px) {
    .pin-div {
      & > div {
        .form__input {
        }
      }
    }
  }
`;
export default CreatePinPage;
