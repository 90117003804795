import styled from '@emotion/styled';
import leftArcImg from 'assets/images/Vector 12.png';
import middleArcImg from 'assets/images/Vector 10.svg';
import dottedBoxesImg from 'assets/images/Group 1387.png';
// import logoImg from "assets/icons/Group 1209 1.png";
import logoImg from 'assets/images/blueLogo 1.png';
import rightArcImg from 'assets/images/Vector 18.png';

//Here you find the reusable for UnAuthpages

export const LeftArc = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 33rem;
  z-index: -1;
  @media screen and (max-width: 560px) {
    width: 5rem;
    display: none;
    backgrond: red;
  }
`;
LeftArc.defaultProps = {
  src: leftArcImg,
};

export const MiddleArc = styled.img`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`;
MiddleArc.defaultProps = {
  src: middleArcImg,
};

export const DottedBoxes = styled.img`
  position: absolute;
  top: 6rem;
  right: 20%;
  z-index: 4;
  width: 250px;
  @media screen and (max-width: 960px) {
    display: none;
  }
`;
DottedBoxes.defaultProps = {
  src: dottedBoxesImg,
  className: 'dotted-box',
};
export const RightArc = styled.img`
  position: absolute;
  bottom: 0;
  right: 10px;
  @media screen and (max-width: 560px) {
    width: 5rem;
  }
`;
RightArc.defaultProps = {
  src: rightArcImg,
  className: 'right-arc',
};
export const UnAuthHeading = styled.h2`
  font-size: 20px;
  line-height: 24px;
  color: #0f227a;
  text-align: center;
  margin: 1rem auto;
`;
export const UnAuthHeadingAddPayment = styled.h2`
  font-size: 24px;
  line-height: 28.8px;
  font-weight: bold;
  color: #0f227a;
  // text-align: center;
  margin: 1rem auto;
`;
export const UnAuthSubHeadingAddPayment = styled.h2`
  font-size: 12px;
  line-height: 14.4px;
  color: #5e5475;
  // text-align: center;
  margin: 1rem auto;
`;

export const UnAuthHeadingSignUp = styled.h2`
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  color: #0f227a;
  text-align: center;
  margin: 1rem auto;
`;

export const UnAuthSubHeading = styled.p`
  font-size: 16px;
  line-height: 35px;
  color: rgba(201, 201, 201, 0.69);
  text-align: center;
  /* margin: 1rem auto; */
`;

export const UnAuthLabel = styled.p`
  font-size: 16px;
  line-height: 35px;
  color: #0f227a;
  text-align: left;
  /* margin: 1rem auto; */
`;

export const UnAuthContainer = styled.div`
  position: relative;
  padding: 1.5rem;
  z-index: 10;
  // width: 45rem;
  width: 70%;
  background-color: white;
  margin: 0 auto;
  top: 5rem;
  // top: 80px;
  // box-shadow: 0px 20px 70px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  margin-bottom: 1rem;
  // margin-bottom: 0rem;
  & input {
    // padding: 3rem 2rem;
    padding: 1rem 1rem;
    // margin: 2.5rem auto;
    margin-bottom: 1.5rem;
  }
  @media screen and (max-width: 960px) {
    width: 80%;
  }
`;

export const UnAuthButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1.5rem auto;
  button {
    border-radius: 50px;
    padding: 3rem 5rem;
  }
`;
export const Logo = styled.img`
  position: absolute;
  top: 5%;
  left: 5%;
  z-index: 10;
  cursor: pointer;
`;
Logo.defaultProps = {
  src: logoImg,
};

export const FlexBox = styled.div`
  display: flex;
  justify-content: space-around;
  position: relative;
`;
